.card{
    width: 100%;
    padding: 20px;
    margin: 10px 0px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card__body{
    margin: 10px 0px;
}

.card__body img{
    margin: 10px 0px;
    margin-bottom: 20px;
}

.card__header__title{
    font-weight: 600;
    font-size: 16px;
    color: #2c2c2c;
    margin-bottom: 10px;
}

.card__texto{
    margin: 10px 0px;
}