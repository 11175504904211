table{
    width: 100%;
    border: 0px;
    border-spacing: 0px;
}

.table{
    /* width: 100%; */
    overflow-x: auto;
    border-radius: 5px;
}

thead{
    background-color: #F3F2F2;
    padding: 20px;
    border-radius: 5px;
    margin: 10px;
}

.thead__item{
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.thead__item label{
    width: 100%;
    text-align: end;
    font-weight: 600;
    color: #6E6B7B;
    /* margin: 0px 10px; */
    margin-bottom: 5px;
    text-align: start;
}

.thead__item__input{
    height: 30px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 5px;
}

.thead__item__input i{
    color: #AAA;
    font-size: 14px;
    margin-right: 5px;
}

.thead__item__input input{
    width: 100%;
    border: none;
    color: #555;
}

tbody tr:nth-child(even){
    background-color: #f3f2f2;
}

tbody tr.tr--yellow{
    background-color: rgba(255, 151, 29, 0.1);
}

tbody tr.tr--yellow .tbody__text p{
    color: #FF971D;
    font-weight: 600;
}

tbody tr.tr--red{
    background: rgba(237, 30, 67, 0.1);
}

tbody tr.tr--red .tbody__text p{
    color: #ED1E43;
    font-weight: 600;
}

.tbody__text{
    padding: 10px;
}

.tbody__text p:nth-child(1){
    color: #6E6B7B;
}

.tbody__text p:nth-child(2){
    color: #999;
}

.pagination{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.pagination__arrow{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEEEE;
    color: #9096e4;
}

.pagination__arrow:hover{
    background-color: #9096e4;
    color: #430BBA;
    cursor: pointer;
}

.pagination__numbers{
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0px 10px;
    background-color: #EFEEEE;
}

.pagination__numbers p{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9096e4;
    cursor: pointer;
}

.pagination__numbers p:hover{
    color: #430BBA;
    font-weight: 600;
}

p.pagination__index{
    width: 34px;
    height: 34px;
    background-color: #430BBA;
    color: #fff;
}

p.pagination__index:hover{
    color: #fff;
}

