.tag{
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 500;
    margin: 5px;
    margin-left: 0px;
    border-radius: 2px;
}


.tag--red{
    color: #fff;
    background-color: #ED1E43;
}

.tag--green{

    background-color: #04CF58;
    color:#FFF;
}

.tag--blue{

    background-color: #0D6EFD;
    color:#FFF;
}

.tag--yellow{

    background-color: #FFBE17;
    color:#FFF;
}

.tag--gray{

    background-color: #4D4C4C;
    color:#FFF;
}


.alert{
    min-width: 300px;
    padding: 6px 10px;
    margin: 10px;
    font-weight: 500;
    margin-left: 0;
    border-radius: 4px;
    font-size: 12px;
    background-color: #ddd;
}

.alert i{
    margin-right: 5px;
}

.alert--red{
    color: #ED1E43;
    background-color: rgba(237, 30, 67, 0.2);
}

.alert--green{
    color: #04CF58;
    background-color: rgba(36, 236, 140, 0.2);
}

.alert--blue{
    color: #24BCEC;
    background-color: rgba(36, 188, 236, 0.2);
}

.alert--purple{
    color: #7021D5;
    background-color: rgba(112, 33, 213, 0.2);
}

.alert--yellow{
    color: #FFBE17;
    background-color: rgba(255, 190, 23, 0.2);
}

.alert--gray{
    color: #999;
    background-color: rgba(170, 170, 170, 0.2);
}



.alert__popup{
    font-size: 12px;
    padding: 10px;

    border-radius: 4px;
    background-color: #430BBA;
    color: #fff;
    
    position: fixed;
    bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.alert__popup i{
    padding-left: 15px;
}