.content__modal{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 2;
}

.overlay{
    width: 100%;
    height: 100vh;
    padding: 100px 0px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.modal__content{
    min-width: 400px;
    max-width: 50%;
    height: fit-content;
    max-height: calc(100vh - 200px);
    padding: 15px 20px;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);

}

.modal__header{
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal__header p{
    font-weight: 600;
    font-size: 14px;
    color: #444;
    margin-bottom: 0;
}

.modal__header i{
    font-size: 14px;
    color: #444;
}

.modal__body--center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal__footer{
    padding: 10px 0px;
}

.modal__footer--right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal__footer--center{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 700px){
    .modal__content{
        min-width: calc(100% - 60px);
        top: 50px;
    }
}