html {
    box-sizing: border-box;
}

* {
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;
    font-family: 'Poppins';
}

a{
    text-decoration: none;
}

p, h1{
    margin: 0;
}
/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d5d5d5; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b5b5b5; 
}

#app{
    background-color: #F7F7F7;
}

nav{
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    padding-top: 24px;

    background-color: #fff;
    z-index: 1;
    transition: width .2s;
}

.nav__header{
    width: 100%;
    padding: 20px 20px 20px 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.nav__header img{
    height: 25px;
    padding: 0px 5px;
}

.nav__subtitle{
    width: 100%;
    padding: 0px 20px 0px 30px;
    font-weight: 600;
    font-size: 12px;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    text-transform: uppercase;
    color: #BBBBBB;
    margin-bottom: 10px;
}


ul{
    padding: 0;
    margin-bottom: 30px;
    list-style: none;
}

ul a:hover{
    text-decoration: none;
}

ul a li{
    width: 210px;
    height: 41px;
    padding: 10px 20px 10px 30px;
    margin-right: 20px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #767676;
    transition: all .2s;
    display: flex;
    align-items: center;
}

ul a li:hover{
    background-color: #EDE7FB;
    color: #430BBA;
    font-weight: 600;
    border-radius: 0px 5px 5px 0px;
}


#nav--active{
    background-color: #EDE7FB;
    color: #430BBA;
    font-weight: 600;
    border-radius: 0px 5px 5px 0px;
}

ul a li i{
    font-size: 14px;
    width: 20px;
    margin-right: 5px;
}

ul a li p{
    transition: all .2s;
    font-size: 1.6rem;
}


.menu__min .nav__subtitle__p, 
.menu__min .nav--item__p,
.menu__min .nav__max__img{
    display: none;
}

.menu__min ul a li{
    width: 60px;
}


.app__body{
    width: 100%;
    min-height: 100vh;
    padding: 30px 30px 30px 260px;
}

.app__body__min{
    padding: 30px 30px 30px 110px;
}

.app__body header{
    width: 100%;
    margin-bottom: 20px;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app__body header p{
   margin: 0;
   font-size: 12px;
   display: flex;
   align-items: center;
   color: #656565;
   padding-right: 10px;
}

.app__body header div{
    display: flex;
    margin: 0%;
}

.app__body header div button{
    font-size: 12px;
    padding-left: 13px;
    border: none;
    background-color: transparent;
    color: #bbb;
    border-left: 1px solid #eee;
    cursor: pointer;
}

.app__body header div button:hover{
    font-weight: 600;
    color: #430BBA;
}

.content{
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
}

.title{
    font-size: 18px;
    margin: 10px 0px;
    font-weight: 600;
    color: #4C4C4C;
}

.texto__title{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    color: #4D4C4C;    
}

.texto__paragrafo{
    font-size: 14px;
    margin-bottom: 0;
    color: #4D4C4C;  
}

.flex--center{
    display: flex;
    align-items: center;
    justify-content: center;
}


.flex--center--column{
    flex-direction: column;
}


.flex--center p{
    text-align: center;
}

.flex--rigth{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


/* Buttons */

.button{
    padding: 7px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    margin: 5px;
    font-weight: 500;
    background-color: transparent;
    transition: background-color .2s ;
    cursor: pointer;
}

.btn__primary{
    background-color: #430BBA;
    color: #fff;
}

.btn__primary:hover{
    background-color: #34098E;
    color: #fff;
}



.btn__primary--outline{
    border: 1px solid #430BBA;
    color: #430BBA;
}

.btn__primary--outline:hover{
    background-color: #430BBA;
    color: #fff;
}

.btn__primary--noborder{
    color: #430BBA;
}

.btn__primary--noborder:hover{
    color: #34098E;
}

/* Button Gray */

.btn__gray{
    background-color: #DCDCDC;
    color: #999999;
}

.btn__gray:hover{
    background-color: #D3D3D3;
    color: #999999;
}

.btn__gray--outline{
    border: 1px solid #D3D3D3;
    color: #999999;
}

.btn__gray--outline:hover{
    background-color: #DCDCDC;
    color: #999999;
}

.btn__gray--noborder{
    color: #999999;
}

.btn__gray--noborder:hover{
    color: #777;
}

/* Button red */

.btn__red{
    background-color: #ED1E43;
    color: #fff;
}

.btn__red:hover{
    background-color: #DE1035;
    color: #fff;
}

.btn__red--outline{
    border: 1px solid #DE1035;
    color: #DE1035;
}

.btn__red--outline:hover{
    background-color: #ED1E43;
    color: #fff;
}

.btn__red--noborder{
    color: #ED1E43;
}

.btn__red--noborder:hover{
    color: #DE1035;
}

button:active, 
button:focus,
input:active, 
input:focus,
textarea:active, 
textarea:focus,
select:active, 
select:focus{
    outline: none;
}

/* Input */

.grupo__campo{
    margin: 10px 0px;
}

.grupo__campo__label{
    font-weight: 500;
    font-size: 12px;
    color: #878787;
    margin: 2px;
}

.grupo__campo__form{
    border-radius: 5px;
    border: 0.5px solid #D8D6DE;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.grupo__campo--input--select{
    height: 38px;
}

.grupo__campo--textarea{
    min-height: 100px;
}

.grupo__campo__form i{
    font-size: 14px;
    padding: 0px 10px;
    padding-right: 5px;
    color: #D8D6DE;
}

.grupo__campo__form input,
.grupo__campo__form select,
.grupo__campo__form textarea{
    width: 100%;
    border: none;
    border-radius: 5px;
    height: 100%;
    font-size: 13px;
    padding: 8px 8px;
    color: #545454;
}

.form--error label, .form--error .grupo__campo__form i{
    color: #DE1035;
}
.form--error .grupo__campo__form{
    border-color: #DE1035;
    filter: drop-shadow(0px 4px 5px rgba(237, 30, 67, 0.1));
}

.form--error {
    border: 1px solid var(--color-roxo-ligth);
    filter: drop-shadow(0px 4px 5px rgba(237, 30, 67, 0.1));
}

::-webkit-input-placeholder { /* Edge */
    color: #B9B9B9;
    font-weight: 400;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B9B9B9;
    font-weight: 400;
}

::placeholder {
    color: #B9B9B9;
    font-weight: 400;
}


.grupo__campo__mark {
    display: block;
    position: relative;
    padding-left: 30px;
    margin: 10px 0px;
    height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #545454;
}
  
.grupo__campo__mark input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #e5e5e5;
    border-radius: 4px;
}
  
.grupo__campo__mark:hover input ~ .checkmark {
    background-color: #ccc;
}

.grupo__campo__mark input:checked ~ .checkmark {
    background-color: #430BBA;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.grupo__campo__mark input:checked ~ .checkmark:after {
    display: block;
}

.grupo__campo__mark .checkmark:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Create a custom radio button */
.checkmarkRadio {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #e5e5e5;
    border-radius: 50%;
}
  
  /* On mouse-over, add a grey background color */
.grupo__campo__mark:hover input ~ .checkmarkRadio {
    background-color: #ccc;
}
  
  /* When the radio button is checked, add a blue background */
.grupo__campo__mark input:checked ~ .checkmarkRadio {
    background-color: #430BBA;
}
  
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkRadio:after {
    content: "";
    position: absolute;
    display: none;
}
  
/* Show the indicator (dot/circle) when checked */
.grupo__campo__mark input:checked ~ .checkmarkRadio:after {
    display: block;
}
  
/* Style the indicator (dot/circle) */
.grupo__campo__mark .checkmarkRadio:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}


.wizard{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wizard__item{
    display: flex;
    align-items: center;
}


.wizard__arrow{
    color: #999;
    margin: 0px 10px;
}

.wizard__index{
    width: 36px;
    height: 36px;
    border-radius: 5px;
    background-color:#e5e5e5;
    color: #aaa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.wizard__p{
    color: #999;
    margin: 0px 10px;
    font-size: 14px;
}

.wizard__item--press .wizard__index{
    background-color: #430BBA;
    color: #fff;
}

.wizard__item--press .wizard__p{
    color: #430BBA;
    font-weight: 600;
}

.wizard__item--left .wizard__index{
    color: #430BBA;
}

.pointer{
    cursor: pointer;
}


.openAlert{
    right: 50px;
    animation-name: open;
    animation-duration: .6s;
}

.closeAlert{
    right: -1000px;
    animation-name: close;
    animation-duration: .6s;
}

@keyframes open {
  from {right: -1000px}
  to {right: 50px;}
}

@keyframes close {
    from {right: 50px}
    to {right: -1000px;}
}

.nav__header #close, .nav__header #bars{
    display: none;
}

.scroll__block{
    overflow-y: hidden;
}

@media(max-width: 700px){
    .nav__header #bars{
        display: block;
    }

    nav{
        width: 100%;
        height: auto;
        position: absolute;
        padding: 0px;
        padding-top: 0px;
    }

    .nav__header{
        padding: 20px 30px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav__header i{
        color: #34098E;
    }

    .nav__subtitle{
        padding: 0px 30px;
    }

    .nav__subtitle i{
        display: none;
    }

    .nav__body{
        display: none;
    }

    #nav--active{
        border-radius: 5px;
    }

    ul a li:hover{
        border-radius: 5px;
    }

    nav.nav__mobile{
        height: 100vh;

    }

    .nav__mobile .nav__header #bars{
        display: none;
    }

    .nav__mobile .nav__header #close{
        display: block;
    }

    .nav__mobile .nav__body{
        display: block;
    }

    ul{
        padding: 0px 30px;
    }
    
    ul a li{
        width: 100%;
    }

    .app__body{
        padding: 30px;
        padding-top: 80px;
    }

    .button{
        max-width: 100%;
    }

    .wizard__p{
        display: none;
    }

    .app__body__min{
        padding: 30px;
        padding-top: 80px;
    }

    .menu__min .nav__subtitle__p, 
    .menu__min .nav--item__p,
    .menu__min .nav__max__img{
        display: block;
    }

    .menu__min ul a li{
        width: 210px;
    }
}