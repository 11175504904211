:root {
    --color-roxo-dark: #7021D5;
    --color-roxo-ligth: #A704BF;
    --color-elements-white-dark: #F7F7F7;
    --color-elements-white: #FFF;
    --color-elements-darkest: #222;
    --color-elements-dark: #4D4D4D;
    --color-elements-medium: #656565;
    --color-elements-light: #757575;
    --color-elements-lightest: #e5e5e5;

    font-size: 47%;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html, body, #root{
    width: 100%;
    height: 100%;
    overflow-x: none;
    color: var(--color-elements-light);
    font-size: 1.5rem;
}

strong{
    font-weight: 600;
}

body{
    background-color: var(--color-elements-white-dark);
}

p{
    margin: 0;
}

.menu{
    width: 220px;
    height: 100vh;
    position: fixed;
    padding: 20px;
    background-color: var(--color-elements-white);
    display: block;
    z-index: 1;
}

.menu__item{
    padding: 8px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-elements-dark);
    transition: all .2s;
}

.menu__item i{
    
    font-size: 1.5rem;
}

.menu__item div{
    display: flex;
    align-items: center;
}

.menu__item div i{
    margin-right: 10px;
    width: 15px;
}

.menu__item div p{
    font-size: 1.6rem;
}

.menu__item:hover{
    text-decoration: none;
    color: #7021D5;
}

.menu__item--active{
    background-color: #F5EFFC;
    color: var(--color-roxo-dark);
    border-radius: 5px;
}

.menu__dropdown{
    width: 100%;
    padding-left: 25px;
}

a.menu__subitem{
    display: block;
    margin-bottom: 5px;
    padding: 8px 10px;
    color: var(--color-elements-light);
    border-bottom: 1px solid var(--color-elements-lightest);
    transition: all .2s;
}

a.menu__subitem:hover{
    background-color: #f5f5f5;
    text-decoration: none;
    border: none;
    border-radius: 2px;
}


.box__content{
    width: 100%;
    min-height: 100%;
    padding: 20px;
    padding-left: 240px;
    background-color: var(--color-elements-white-dark);
}

.box__content__print{
    width: 100%;
    min-height: 100%;
    padding: 25px;
    padding-left: 240px;
    background-color: white;
}


.box__content__print ol{
    padding-left: 2rem;
}

.box__content__print ul {
    list-style: disc;
    padding-left: 2rem;
}

.box__header{
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: var(--color-elements-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box__header p{
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-elements-light);
}

.box__header div{
    display: flex;
    align-items: center;
}

.box__header div p{
    font-weight: 400;
}

.box__header div p:nth-child(1){
    padding-right: 10px;
    border-right: 1px solid var(--color-elements-light);
}

.box__header .out{
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #bbb;
    cursor: pointer;
}

.box__header .out p{
    color: #bbb;
    padding-left: 5px;
}

.box__header .out:hover{
    color: #bbb;
    text-decoration: none;
}

.box__body{
    width: 100%;
    padding: 20px;
    background-color: var(--color-elements-white);
    border-radius: 5px;
}

.relatorio__header{
    display: flex;
    justify-content: space-between;
}

.relatorio__header div p{
    padding: 5px 0px;
}

.relatorio__header div:nth-child(2){
    text-align: right;
}

.relatorio__body{
    width: 100%;
}

.table-responsive{
    padding: 20px 0px;
}

table tr th{
    color: var(--color-elements-light);
    white-space: nowrap;
}

table tr{
    color: var(--color-elements-light);
}

table tr td{
    height: 40px;
    padding: 5px 10px !important;
}

.table td, .table th {
    border: none;
}

.d-flex-align-center{
    display: flex;
    align-items: center;
}

.table-responsive::-webkit-scrollbar{
    height: 6px;
}

.table-responsive::-webkit-scrollbar-track{
    background-color: var(--color-elements-white-dark);
}

.table-responsive::-webkit-scrollbar-thumb{
    background: rgb(167,4,176);
    background: linear-gradient(90deg, rgba(167,4,176,1) 0%, rgba(112,33,213,1) 100%);
    border-radius: 6px;
}

.table-responsive::-webkit-scrollbar-thumb:hover{
    background-color:#000;
}

.debito{
    color: #A704BF;
}

.total{
    background-color: var(--color-elements-light) !important;
}

.total td{
    color: #fff !important;
}

.menu__mob{
    display: none;
    padding: 10px 20px;
}

.grupo__campo{
    width: 100%;
    margin: 10px 0px;
}

.grupo__campo label{
    padding: 0px 15px;
    font-weight: 500;
    color: var(--color-elements-dark);
}

.grupo__input{
    width: 100%;
    height: 35px;
    border: 1px solid #c5c5c5;
    padding: 0px 15px;
    border-radius: 35px;
    display: flex;
    align-items: center;
}

.grupo__input i{
    padding-left: 10px;
}

.grupo__input input, .grupo__input select{
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
}

.grupo__input__textarea{
    width: 100%;
    min-height: 60px;
    border: 1px solid #c5c5c5;
    padding: 0px 5px;
    border-radius: 15px;
}

.grupo__input__textarea textarea{
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding: 5px 10px;
}

input, select, textarea, button{
    outline: 0;
    color: #888;
}

button:active, button:focus{
    outline: 0;
}

.input-active-border{
    border: 1px solid var(--color-roxo-ligth);
}

/* Radios */

.input__check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}

  label.input__check {
      padding-left: 35px;
      color: var(--color-elements-light);
      font-weight: 400;
}
  
  /* Hide the browser's default radio button */
  .input__check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
  
  /* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 10px;
    height: 20px;
    width: 20px;
    background-color: #f5f5f5;
    border-radius: 50%;
}
  
  /* On mouse-over, add a grey background color */
.input__check:hover input ~ .checkmark {
    background-color: #e5e5e5;
}
  
  /* When the radio button is checked, add a blue background */
.input__check input:checked ~ .checkmark {
      background: rgb(167,4,176);
      background: linear-gradient(90deg, rgba(112,33,213,1) 0%, rgba(167,4,176,1) 100%);
}
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
  /* Show the indicator (dot/circle) when checked */
.input__check input:checked ~ .checkmark:after {
    display: block;
}
  
  /* Style the indicator (dot/circle) */
.input__check .checkmark:after {
    left:8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark__checkbox{
    position: absolute;
    top: 0;
    left: 10px;
    height: 20px;
    width: 20px;
    background-color: #f5f5f5;
    border-radius: 2px;
}

.btn__freedom{
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 40px;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 5px 0px;
}

.btn--desativado{
    background-color: #ccc;
    color: var(--color-elements-light);
}

.btn--normal{
    background-color: #fff;
    color: var(--color-roxo-ligth);
    border: 1px solid var(--color-roxo-ligth);
}

.btn--normal:hover{
    border: 0;
    background: rgb(167,4,176);
    background: linear-gradient(90deg, rgba(112,33,213,1) 0%, rgba(167,4,176,1) 100%);
    color: #fff;
}

.btn--active{
    background: rgb(167,4,176);
    background: linear-gradient(90deg, rgba(112,33,213,1) 0%, rgba(167,4,176,1) 100%);
    color: #fff;
}

.title{
      font-size: 2.2rem;
      color: var(--color-elements-darkest);
      margin-bottom: 5px;
}

.subtitle{
      font-size: 1.6rem;
      padding-bottom: 15px;
      font-weight: 500;
      color: var(--color-elements-dark);
}

.text{
    line-height: 160%;
    padding-bottom: 10px;
}

.card__freedom{
    width: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.card__header{
    padding: 15px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
}

.status{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px;
    background-color: var(--color-roxo-dark);
    color: #fff;
    font-size: 1.4rem;
}

.card__body{
    padding: 0px 15px;
}

.labels{
    width: fit-content;
    padding: 5px 15px;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 3px;
    margin: 5px;
}

.labels span{
    width: fit-content;
}

.status--sucesso{
    background-color: rgb(36, 211, 124);
    color: #fff;
}

.status--negativo{
    background-color: rgb(207, 47, 7);
    color: #fff;
}

.status--desativado{
    background-color: #c5c5c5;
    color: #757575;
}

/* Novas linhas */

.wizard{
    width: 100%;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    border-radius: 5px;
    background-color: #fff;
}

.wizard__item{
    min-width: 150px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: #495057;
    font-weight: 500;
    cursor: pointer;
}

.wizard__step{
    width: 30px;
    height: 30px;
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    line-height: 26px;
    border: 2px solid var(--color-roxo-dark);
    border-radius: 50%;
    color: var(--color-roxo-dark);
}

.wizard__step--active{
    color: #fff;
    background-color: var(--color-roxo-dark);
}

.wizard__step--desactive{
    color: #757575;
    background-color: #f1f1f1;
    border: #f1f1f1;
    line-height: 34px;
}

.wizard__dots{
    width: 100%;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wizard__dots button{
    width: auto;
    padding: 0px 40px;
}

.pag{
    width: calc(100% - 30px);
    padding-bottom: 10px;
    margin: 0 auto;
    display: none;
}

.btn--before{
    color: var(--color-elements-light);
    background-color: transparent;
    border: 1px solid #ccc;
    transition: all .2s;
}

.btn--before:hover{
    background-color: #efefef;
    border: none;
}

.relatorio-title{
    font-weight: 600;
    font-size: 14px;
}

.dashboard__head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard__head h5{
    font-weight: 500;
    font-size: 16px;
    font-family: 'Poppins';
    color: #656565;
}

.dashboard__item{
    padding: 15px 25px;
    min-height: 93px;
    background-color: #F9F9FF;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer ;
}

.dashboard__item__legenda{
    top: -15px;
    font-size: 1.6rem;
    padding: 3px;
    background-color: var(--color-roxo-dark);
    position: absolute;
    border-radius: 3px;
}

.dashboard__item__legenda p{
    color: #fff;
    padding: 0px 5px;
}

.dashboard__item__text{
    display: flex;
    align-items: center;
}

.dashboard__item__text h3{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Poppins';
    margin-bottom: 0px;
    color: #454545;
}

.dashboard__item__text p{
    font-size: 1.7rem;
    font-family: 'Poppins';
    color: #A4A4A4;
}

.dashboard__item__text span{
    font-size: 2.5rem;
}

.dashboard__item__text div{
    margin-right: 20px;
}

.dashboard__item__icon{
    padding: 0px 10px;
}

.dashboard__item__circle{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f1fc;
    color: #454545;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
}

.menu__btn{
    position: fixed;
    left: 0;
    top: 20vh;
    cursor: pointer;
    background-color: #fff;
    padding: 15px;
    font-size: 2rem;
    border-radius: 0px 5px 5px 0px;
    box-shadow: 2px 3px 5px -3px rgb(208 208 255);
    -webkit-box-shadow: 2px 3px 5px -3px rgb(208 208 255);
    -moz-box-shadow: 2px 3px 5px -3px rgb(208 208 255);
    z-index: 1;
}

.dashboard__section{
    padding: 15px 25px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: #F9F9FF;
}

.dashboard__section .dashboard__item__text{
    display: block;
}

.dashboard__section__titulo{
    font-weight: 600;
    font-size: 1.7rem;
    margin-bottom: 15px;
}

.line{
    width: 50%;
    height: 1px;
    margin: 20px 0px;
    background-color: #ddd;
}

.grupo__input__type_2{
    width: 100%;
}

.grupo__input__type_2 label{
    font-size: 1.8rem;
    font-weight: 600;
    color: #454545;
}

.grupo__input__type_2 .grupo__campo{
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: #F9F9FF;
    padding: 10px 15px;
    border-radius: 5px;
}

.grupo__input__type_2 .grupo__campo i{
    font-size: 1.8rem;
    color: #7021D5;
    margin-right: 10px;
}

.grupo__input__type_2 .grupo__campo input{
    width: 100%;
    background-color: transparent;
    border: none;
    color: #555;
    font-weight: 500;
}

.erro{
    padding: 10px;
    text-align: end;
    color: var(--color-roxo-ligth);
    font-weight: 600;
}

@media print{
    .tohidden, a.out, #box_content_header, #menu, .box-paginacao {
        display: none !important;
    }

    .toshow{
        display: block !important;
    }

    .box__content{
        padding-left: 0 !important;
    }

    body{
        min-width: 0px !important;
    }
}

@media (max-width: 768px){

    .box__header{
        flex-direction: column;
        align-items: flex-start;
    }

    .menu__mob{
        display: block;
    }

    .menu__mob .menu__img{
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .menu__mob .menu__img i{
        display: block;
        cursor: pointer;
        padding-right: 10px;
    }

    .menu{
        width: 100%;
        display: none;
        padding: 10px 20px;
    }

    .menu .menu__img i{
        display: block;
        padding-right: 10px;
    }
    
    .box__content{
        padding-left: 20px;
    }

    .relatorio__header{
        flex-direction: column-reverse;
    }

    .relatorio__header div:nth-child(2){
        text-align: left;
    }

    .dashboard__head{
        align-items: flex-start;
        flex-direction: column;
    }

    .dashboard__item__icon{
        display: none;
    }
}

.btns{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}